export default class Carousel {
  constructor(options) {
    this.items = document.querySelectorAll(`.${options.itemClass}`);
    this.currentClass = `${options.itemClass}${options.currentClassSuffix}`;
    this.prevClass = `${options.itemClass}${options.previousClassSuffix}`;
    this.nextClass = `${options.itemClass}${options.nextClassSuffix}`;
    this.intermediateNextClass = options.intermediateClassSuffix
      ? `${options.itemClass}${options.intermediateClassSuffix[0]}`
      : undefined;
    this.intermediatePreviousClass = options.intermediateClassSuffix
      ? `${options.itemClass}${options.intermediateClassSuffix[1]}`
      : undefined;
    this.currentItemIdx = this.currentItemIdx();
    this.options = options;
    this.dots = this.getDots();
    this.deltaWheel = 0;
    this.getControls();
  }

  getControls() {
    const { controls } = this.options;
    Object.keys(controls).forEach((key) => {
      switch (key) {
        case 'buttons':
          this.buttons(controls.buttons);
          break;
        case 'scroll':
          this.scroll(controls.scroll);
          break;
        case 'keyboard':
          this.keyboard(controls.keyboard);
          break;
        case 'autoScroll':
          this.autoScroll(controls.autoScroll);
          break;
        default:
          break;
      }
    });
    if (this.dots !== undefined) {
      this.clickableDots(this.getClickableDots());
      this.dotsTransition(1, 0);
    }
  }

  getClickableDots() {
    const clickableKinds = [];
    this.dots.forEach((element, idx) => {
      if (element.clickable !== false) {
        clickableKinds.push(idx);
      }
    });
    return clickableKinds;
  }

  getDots() {
    const dots = [];
    if (typeof this.options.dots === 'object') {
      if (Array.isArray(this.options.dots)) {
        this.options.dots.forEach((element) => {
          const elementCopy = element;
          elementCopy.nodes = document.querySelectorAll(
            `.${elementCopy.dotsClass}`,
          );
          dots.push(elementCopy);
        });
      } else {
        const element = this.options.dots;
        element.nodes = document.querySelectorAll(
          `.${this.options.dots.dotsClass}`,
        );
        dots.push(element);
      }
      return dots;
    }
    return dots;
  }

  currentItemIdx() {
    let index;
    this.items.forEach((item, idx) => {
      if (item.classList.contains(this.currentClass)) {
        index = idx;
      }
    });
    return index;
  }

  nextItem() {
    const intermediateClasses = [this.intermediatePreviousClass, this.intermediateNextClass];
    this.removeIntermediates(intermediateClasses);
    const current = this.currentItemIdx;
    const next = this.nextIndex(current);
    const previous = this.prevIndex(current);
    const nextnext = this.items[this.nextIndex(next)];

    try {
      nextnext.classList.add(this.intermediateNextClass);
    } finally {
      setTimeout(() => {
        try {
          // this.items[current].classList.add(this.intermediatePreviousClass);
        } finally {
          this.items[previous].classList.remove(this.prevClass);
          this.items[current].classList.remove(this.currentClass);

          this.items[current].classList.add(this.prevClass);
          this.items[next].classList.add(this.currentClass);

          nextnext.classList.add(this.nextClass);

          this.items[next].classList.remove(this.nextClass);
        }
      }, 100);
    }

    this.currentItemIdx = next;
    this.dotsTransition(current, next);

    return [current, next];
  }

  previousItem() {
    const intermediateClasses = [this.intermediatePreviousClass, this.intermediateNextClass];
    this.removeIntermediates(intermediateClasses);
    const current = this.currentItemIdx;
    const next = this.nextIndex(current);
    const previous = this.prevIndex(current);
    const previousprevious = this.items[this.prevIndex(previous)];
    try {
      previousprevious.classList.add(this.intermediatePreviousClass);
    } finally {
      setTimeout(() => {
        try {
          // this.items[next].classList.add(this.intermediateNextClass);
        } finally {
          this.items[next].classList.remove(this.nextClass);
          this.items[current].classList.remove(this.currentClass);

          this.items[current].classList.add(this.nextClass);
          this.items[previous].classList.add(this.currentClass);

          previousprevious.classList.add(this.prevClass);

          this.items[previous].classList.remove(this.prevClass);
        }
      }, 100);
    }

    this.currentItemIdx = previous;
    this.dotsTransition(current, previous);

    return [previous, current];
  }

  jumpToItem(idx, cycleTimeout) {
    const current = this.currentItemIdx;
    if (idx > current) {
      this.nextItem();
      setTimeout(() => this.jumpToItem(idx, cycleTimeout), cycleTimeout);
    } else if (idx < current) {
      this.previousItem();
      setTimeout(() => this.jumpToItem(idx, cycleTimeout), cycleTimeout);
    }
  }

  dotsTransition(remove, add) {
    if (this.dots !== undefined) {
      this.dots.forEach((element) => {
        if (element.trailingDots === true) {
          element.nodes.forEach((node, idx) => {
            if (idx <= add) {
              element.nodes[idx].classList.add(
                `${element.dotsClass}${element.activeClassSuffix}`,
              );
            } else {
              element.nodes[idx].classList.remove(
                `${element.dotsClass}${element.activeClassSuffix}`,
              );
            }
          });
        } else {
          if (element.nodes[remove] !== undefined) {
            element.nodes[remove].classList.remove(
              `${element.dotsClass}${element.activeClassSuffix}`,
            );
          }
          if (element.nodes[add] !== undefined) {
            element.nodes[add].classList.add(
              `${element.dotsClass}${element.activeClassSuffix}`,
            );
          }
        }
      });
    }
  }

  removeIntermediates(intermediates) {
    intermediates.forEach((element) => {
      const nodes = document.querySelectorAll(`.${element}`);
      nodes.forEach((node) => {
        node.classList.remove(element);
      });
    });
  }

  nextIndex(idx) {
    return idx + 1 !== this.items.length ? idx + 1 : 0;
  }

  prevIndex(idx) {
    return idx === 0 ? this.items.length - 1 : idx - 1;
  }

  buttons(options) {
    const nextButton = document.querySelector(`.${options.nextButtonClass}`);
    const previousButton = document.querySelector(
      `.${options.previousButtonClass}`,
    );

    nextButton.addEventListener('click', () => this.nextItem());
    previousButton.addEventListener('click', () => this.previousItem());
  }

  handleScroll(options, event) {
    if (this.deltaWheel > options.treshold) {
      this.deltaWheel = 0;
      this.nextItem();
    } else if (Math.abs(this.deltaWheel) > options.treshold) {
      this.deltaWheel = 0;
      this.previousItem();
    } else {
      this.deltaWheel += event.deltaY;
    }
  }

  scroll(options) {
    window.addEventListener('wheel', (event) => this.handleScroll(options, event));
  }

  keyboard(options) {
    const next = options.keyNext;
    const previous = options.keyPrevious;
    document.addEventListener('keydown', (key) => {
      if (key.keyCode === next) {
        this.nextItem();
      } else if (key.keyCode === previous) {
        this.previousItem();
      }
    });
  }

  autoScroll(options) {
    window.setInterval(() => {
      this.nextItem();
    }, options.timer);
  }

  clickableDots(clickableKinds) {
    if (clickableKinds !== undefined) {
      clickableKinds.forEach((element) => {
        const dots = this.dots[element].nodes;
        const cycleTimeout = this.dots[element].clickable;
        dots.forEach((dot, idx) => {
          dot.addEventListener('click', () => this.jumpToItem(idx, cycleTimeout));
        });
      });
    }
  }
}

window.Carousel = Carousel;
